import React from 'react'
import { Form, FormGroup, Input } from 'reactstrap'
import { useTranslation } from 'react-i18next';
import { GroupConsumerHook } from '../../providers/groupProvider'

const GroupSelector = () => {
  const [{ groups, currentGroup }, dispatch] = GroupConsumerHook();
  const { t } = useTranslation();

  const handleChange = (e) => {
    dispatch({
      type: 'changeCurrentGroup',
      newCurrentGroupId: e.target.value,
    });
  };

  return (
    <Form inline>
      <FormGroup>
        <Input value={currentGroup ? currentGroup._id : 'NULL'} type="select" onChange={handleChange}>
          <option value="NULL" disabled>{t('screen.home.sections.myGroups.selectGroup.placeholder')}</option>
          {
        groups.map((group) => (
          <option value={group._id} key={group._id}>{ group.name }</option>
        ))
      }
        </Input>
      </FormGroup>
    </Form>
  );
};

export default GroupSelector;
