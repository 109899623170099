import React, { useState, useEffect } from 'react'
import moment from 'moment'
import 'moment/locale/fr';
import {
  Jumbotron, Spinner, Row, Col, Button,
} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { getSentMessages } from '../../query/query'
import { deleteMessage } from '../../mutation/mutation'
import i18n from '../../i18n';

const SentMessages = () => {
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      if (messages.length > 0) return;

      setLoading(true);

      try {
        const { data } = await getSentMessages();
        setMessages(data.sentMessages);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Jumbotron>
      {
        loading
          ? (
            <Spinner color="primary" />
          )
          : (
            <div>
              <h1 style={{ marginBottom: '30px' }}>{t('screen.sentMessages.title')}</h1>
              <div>
                {
                  messages && messages.length === 0 && (
                    <h2>
                      {t('screen.sentMessages.noMessage')}
                    </h2>
                  )
                }
                {
                  messages.map((m) => (
                    <div>
                      <Row style={{ marginBottom: '20px' }}>
                        <Col>
                          {m.recipientName}
                        </Col>
                        <Col>
                          {`${moment(m.date).isAfter(moment()) ? t('screen.sentMessages.scheduledFor') : t('screen.sentMessages.sentOn')} ${moment(m.date).locale(i18n.language).format('LLL')}`}
                        </Col>
                        <Col>
                          {moment(m.date).isAfter(moment()) && t('screen.sentMessages.scheduled')}
                          {moment(m.date).isSameOrBefore(moment()) && (m.status === 'delivered' ? t('screen.sentMessages.delivered') : t('screen.sentMessages.sent'))}
                        </Col>
                        <Col>
                          <img
                            alt="preview"
                            src={m.url}
                            style={{
                              width: '320px',
                              height: '240px',
                            }}
                          />
                        </Col>
                        <Col>
                          <Button
                            type="button"
                            onClick={async () => {
                              // eslint-disable-next-line no-alert
                              if (window.confirm('Are you sure to delete this messages?')) {
                                await deleteMessage(m._id);
                                window.location.reload();
                              }
                            }}
                            color="danger"
                            className="mt-2"
                            style={{
                              position: 'relative', left: 10, top: -3, height: 36, width: 150,
                            }}
                          >
                            {loading ? <Spinner color="primary" style={{ width: 20, height: 20 }} /> : t('screen.home.sections.myGroups.newGroup.buttons.delete')}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  ))
                }
              </div>
            </div>
          )
      }
    </Jumbotron>
  )
}

export default SentMessages;
