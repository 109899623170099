/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
  BrowserRouter as Router, Route, Redirect, Switch,
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Navigation from '../components/Navigation/Navigation'

import LoginPage from '../pages/Login/Login'
import CapitalOnePage from '../pages/CapitalOne/CapitalOne'
import GroupsPage from '../pages/Groups/Groups'
import SendMessagePage from '../pages/SendMessage/SendMessage'
import SentMessagesPage from '../pages/SendMessage/SentMessages'
import ScheduledMessagesPage from '../pages/SendMessage/ScheduledMessages'
import { GroupProvider } from '../providers/groupProvider'

// redirect on / when logged in
function LoginRouter({ ...rest }) {
  const { t } = useTranslation();
  return (
    <Route
      {...rest}
      render={() => (
        rest.token
          ? <Redirect to="/" />
          : <LoginPage {...rest} t={t} />
      )}
    />
  )
}

function Authenticated({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        rest.token
          ? <Component {...props} />
          : <Redirect to="/login" />
      )}
    />
  )
}

const isCapitalOne = (companyId) => companyId === process.env.REACT_APP_CAPITAL_ONE_ID;

const RouterB2B = ({
  onLogin,
  onLogout,
  token,
  companyId,
  companyName,
}) => (
  <Router>
    <Navigation
      token={token}
      onLogout={onLogout}
      companyId={companyId}
      companyName={companyName}
    />
    <Switch>
      <LoginRouter
        exact
        path="/login"
        token={token}
        onLogin={onLogin}
      />
      <GroupProvider>
        <Authenticated
          exact
          path="/"
          component={isCapitalOne(companyId) ? CapitalOnePage : GroupsPage}
          token={token}
        />
        <Authenticated
          exact
          path="/send-message"
          component={SendMessagePage}
          token={token}
        />
        <Authenticated
          exact
          path="/sent-messages"
          component={SentMessagesPage}
          token={token}
        />
        <Authenticated
          exact
          path="/scheduled-messages"
          component={ScheduledMessagesPage}
          token={token}
        />
      </GroupProvider>
    </Switch>
  </Router>
);

export default RouterB2B
