import React, { useMemo, useState } from 'react'
import {
  Button, Form, FormGroup, Input,
} from 'reactstrap'
import { useTranslation } from 'react-i18next';
import { GroupConsumerHook } from '../../providers/groupProvider'

const NameSelector = ({ handleSendImageToLoveboxName, haveBase64 }) => {
  const [{ groups, currentGroup }, dispatch] = GroupConsumerHook();
  const { t } = useTranslation();
  const [selectedName, setSelectedName] = useState('NULL');

  const handleGroupChange = (e) => {
    setSelectedName('NULL');
    dispatch({
      type: 'changeCurrentGroup',
      newCurrentGroupId: e.target.value,
    });
  };

  const handlNameChange = (e) => {
    setSelectedName(e.target.value);
  };

  const nameOfCurrentGroup = useMemo(() => {
    if (!currentGroup) return [];
    const names = currentGroup.recipients.map((recipient) => recipient.name).filter(Boolean);
    return names
  }, [currentGroup]);

  return (
    <div>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '10px',
      }}
      >
        <span style={{
          alignSelf: 'center',
          marginRight: '10px',
        }}
        >
          {t('screen.sendMessage.send.toName.Group')}
        </span>
        <Form inline>
          <FormGroup>
            <Input value={currentGroup ? currentGroup._id : 'NULL'} type="select" onChange={handleGroupChange}>
              <option value="NULL" disabled>{t('screen.sendMessage.send.toName.placeholderGroup')}</option>
              {
            groups.map((group) => (
              <option value={group._id} key={group._id}>{ group.name }</option>
            ))
          }
            </Input>
          </FormGroup>
        </Form>
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
      }}
      >
        <span style={{
          alignSelf: 'center',
          marginRight: '10px',
        }}
        >
          {t('screen.sendMessage.send.toName.Name')}
        </span>
        <Form inline>
          <FormGroup>
            <Input value={selectedName} type="select" onChange={handlNameChange}>
              <option value="NULL" disabled>{t('screen.sendMessage.send.toName.placeholderName')}</option>
              {
              nameOfCurrentGroup.map((name) => (
                <option value={name} key={name}>{ name }</option>
              ))
            }
            </Input>
          </FormGroup>
        </Form>
      </div>
      <Button
        disabled={!haveBase64 || !selectedName || selectedName === 'NULL'}
        className="mt-4"
        onClick={() => handleSendImageToLoveboxName(selectedName)}
        color="primary"
      >
        {selectedName && selectedName !== 'NULL'
          ? t('screen.sendMessage.send.toName.buttonName', { loveboxName: selectedName })
          : t('screen.sendMessage.send.toName.button')}
      </Button>
    </div>
  );
};

export default NameSelector;
