import React, { useState } from 'react'
import {
  Form,
  FormGroup,
  Button,
  Input,
  Spinner,
} from 'reactstrap'
import { useTranslation } from 'react-i18next';
import { GroupConsumerHook } from '../../providers/groupProvider'
import { renameGroup } from '../../mutation/mutation'

const RenameGroupForm = () => {
  const [renamedGroupName, setRenamedGroupName] = useState('');
  const [loading, setLoading] = useState(false);
  const [{ currentGroup }, dispatch] = GroupConsumerHook();
  const { t } = useTranslation();

  const handleRenameGroup = async () => {
    setLoading(true);

    try {
      const { data } = await renameGroup(currentGroup._id, renamedGroupName);
      dispatch({
        type: 'renameGroup',
        renamedGroupId: data.group._id,
        renamedGroupName: data.group.name,
      })
      setRenamedGroupName('');
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Form inline>
      <FormGroup>
        <Input type="text" placeholder={t('screen.home.sections.myGroups.newGroup.placeholder')} value={renamedGroupName} onChange={(e) => setRenamedGroupName(e.target.value)} />
        <Button
          type="button"
          disabled={!currentGroup}
          onClick={handleRenameGroup}
          className="ml-2"
        >
          {t('screen.home.sections.myGroups.newGroup.buttons.rename')}
        </Button>
        {
          loading && (
            <Spinner color="primary" className="ml-2" />
          )
        }
      </FormGroup>
    </Form>
  );
};

export default RenameGroupForm;
