import React, { useState } from 'react'
import { Button, Spinner } from 'reactstrap'
import { useTranslation } from 'react-i18next';
import { GroupConsumerHook } from '../../providers/groupProvider'
import { deleteGroup } from '../../mutation/mutation'

const DeleteGroup = () => {
  const [loading, setLoading] = useState(false);
  const [{ currentGroup }, dispatch] = GroupConsumerHook();
  const { t } = useTranslation();

  const handleDeleteGroup = async () => {
    // eslint-disable-next-line
    if (confirm(`Are you sure to delete group "${currentGroup.name}" ?`)) {
      setLoading(true);

      try {
        await deleteGroup(currentGroup._id);
        dispatch({
          type: 'deleteGroup',
          deletedGroupId: currentGroup._id,
        })
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <Button
      type="button"
      disabled={!currentGroup}
      onClick={handleDeleteGroup}
      color="danger"
      className="mt-2"
      style={{
        position: 'relative', left: 10, top: -3, height: 36, width: 150,
      }}
    >
      {loading ? <Spinner color="primary" style={{ width: 20, height: 20 }} /> : t('screen.home.sections.myGroups.newGroup.buttons.delete')}
    </Button>
  );
};

export default DeleteGroup;
