import axios from 'axios'
import { URI } from '../providers/apolloClient'

const activateLovebox = (macAddress) => axios.post(`${URI}/b2b/api/box/${macAddress}/activate?company=${localStorage.getItem('companyId')}`, {}, { headers: { Authorization: localStorage.getItem('token') } })
  .then((res) => res)

const createGroup = (groupName) => axios.post(`${URI}/b2b/api/group/`, { name: groupName }, { headers: { Authorization: localStorage.getItem('token') } })
  .then((res) => res)

const deleteGroup = (groupId) => axios.delete(`${URI}/b2b/api/group/${groupId}`, { headers: { Authorization: localStorage.getItem('token') } })
  .then((res) => res)

const renameGroup = (groupId, groupName) => axios.put(`${URI}/b2b/api/group/${groupId}`, { name: groupName }, { headers: { Authorization: localStorage.getItem('token') } })
const removeUsersToGroupByEmails = (groupId, emails) => axios.post(`${URI}/b2b/api/group/${groupId}/remove-users`, { emails }, { headers: { Authorization: localStorage.getItem('token') } })
  .then((res) => res)

const removeUsersToGroupByMacAddresses = (groupId, macAddresses) => axios.post(`${URI}/b2b/api/group/${groupId}/remove-boxes`, { macAddresses }, { headers: { Authorization: localStorage.getItem('token') } })
  .then((res) => res)

const sendImageToGroup = (groupId, data) => axios.post(`${URI}/b2b/api/group/${groupId}/message`, { ...data }, { headers: { Authorization: localStorage.getItem('token') } })
  .then((res) => res)

const deleteMessage = (messageId) => axios.delete(`${URI}/b2b/api/group/messages/${messageId}`, { headers: { Authorization: localStorage.getItem('token') } })
  .then((res) => res)

const sendImageToUser = (macAddress, data) => axios.post(`${URI}/b2b/api/group/${macAddress}/mac-address-message`, { ...data }, { headers: { Authorization: localStorage.getItem('token') } })
  .then((res) => res)

const changeDefaultImage = (macAddress, base64) => axios.post(`${URI}/b2b/api/group/${macAddress}/change-default-image`, { base64 }, { headers: { Authorization: localStorage.getItem('token') } })
  .then((res) => res)

const addUsersToGroupByEmail = (groupId, emails) => axios.post(`${URI}/b2b/api/group/${groupId}/add-users`, { emails }, { headers: { Authorization: localStorage.getItem('token') } })
  .then((res) => res)

const addUsersToGroupByMacAddresses = (groupId, macAddresses) => axios.post(`${URI}/b2b/api/group/${groupId}/add-boxes`, { macAddresses }, { headers: { Authorization: localStorage.getItem('token') } })
  .then((res) => res)

export {
  activateLovebox,
  createGroup,
  deleteGroup,
  renameGroup,
  deleteMessage,
  removeUsersToGroupByEmails,
  removeUsersToGroupByMacAddresses,
  sendImageToGroup,
  sendImageToUser,
  addUsersToGroupByEmail,
  addUsersToGroupByMacAddresses,
  changeDefaultImage,
}
