import React, { useState } from 'react'
import { Jumbotron } from 'reactstrap'
import LoveboxForm from './forms/lovebox-form'

const CheckStatus = () => {
  const [emailBox, setEmailBox] = useState('');

  return (
    <div>
      <Jumbotron>
        <h3 className="display-4">
          Loveboxes status
        </h3>
        <hr className="my-2" />

        <LoveboxForm
          email={emailBox}
          setEmail={setEmailBox}
        />

      </Jumbotron>
    </div>
  );
};

export default CheckStatus;
