import React from 'react'
import {
  Form, FormGroup, Label, Input, Container, Row, Col,
} from 'reactstrap'

const CustomerSearch = ({ email, setEmail }) => (
  <Form>
    <FormGroup>
      <Label>Owner&apos;s email</Label>
      <Container>
        <Row>
          <Col>
            <Input
              type="email"
              placeholder="example@mail.com"
              value={email}
              onChange={({ target: { value } }) => setEmail(value.toLowerCase())}
            />
          </Col>
        </Row>
      </Container>
    </FormGroup>
  </Form>
)

export default CustomerSearch;
