import React, { useState } from 'react'
import {
  Form,
  FormGroup,
  Button,
  Input,
  Spinner,
} from 'reactstrap'
import { useTranslation } from 'react-i18next';
import { GroupConsumerHook } from '../../providers/groupProvider'
import { createGroup } from '../../mutation/mutation'

const AddGroup = () => {
  const [groupName, setGroupName] = useState('');
  const [loading, setLoading] = useState(false);
  const [, dispatch] = GroupConsumerHook();
  const { t } = useTranslation();

  const handleCreateGroup = async () => {
    setLoading(true);

    try {
      const { data } = await createGroup(groupName);
      dispatch({
        type: 'addGroup',
        newGroup: data.group,
      })
      setGroupName('');
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Form inline>
      <FormGroup>
        <Input type="text" placeholder={t('screen.home.sections.createGroup.placeholder')} value={groupName} onChange={(e) => setGroupName(e.target.value)} />
        <Button type="button" onClick={handleCreateGroup} className="ml-2">{t('screen.home.sections.createGroup.button')}</Button>
        {
          loading && (
            <Spinner color="primary" className="ml-2" />
          )
        }
      </FormGroup>
    </Form>
  );
};

export default AddGroup;
