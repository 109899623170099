import React, { useState } from 'react'
import { createRoot } from 'react-dom/client';

import 'bootstrap/dist/css/bootstrap.min.css'
import './styles.css'

import RouterB2B from './router/router'

import storage from './providers/storage'

import i18n from './i18n';

const App = () => {
  const [token, setToken] = useState(storage.getToken())
  const [companyId, setCompanyId] = useState(storage.getCompanyId());
  const [companyName, setCompanyName] = useState(storage.getCompanyName());

  const savedLanguage = window.localStorage.getItem('language');
  if (savedLanguage) {
    i18n.changeLanguage(window.localStorage.getItem('language'));
  }

  const handleLogin = ({
    companyId: _companyId,
    companyName: _companyName,
    companyToken,
    domainNames,
  }) => {
    storage.setStorage({
      companyId: _companyId,
      companyName: _companyName,
      companyToken,
      domainNames,
    })

    setToken(companyToken);
    setCompanyId(_companyId);
    setCompanyName(_companyName);
  }

  const handleLogout = () => {
    localStorage.clear()

    setToken(null);
    setCompanyId('');
    setCompanyName('');
  }

  return (
    <div className="App">
      <div>
        <RouterB2B
          onLogin={handleLogin}
          onLogout={handleLogout}
          token={token}
          companyId={companyId}
          companyName={companyName}
        />
      </div>
    </div>
  )
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);
