import React, { useState, useEffect } from 'react'
import {
  Jumbotron, Spinner, Row, Col,
} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import AddGroup from '../../components/AddGroup/AddGroup'
import GroupSelector from '../../components/GroupSelector/GroupSelector'
import RecipientList from '../../components/RecipientList/RecipientList'
import RenameGroup from '../../components/RenameGroup/RenameGroup'
import DeleteGroup from '../../components/DeleteGroup/DeleteGroup'
import AddRecipients from '../../components/AddRecipients/AddRecipients'
import { GroupConsumerHook } from '../../providers/groupProvider'
import { getGroups } from '../../query/query'

const Groups = () => {
  const [{ groups, currentGroup }, dispatch] = GroupConsumerHook();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      if (groups.length > 0) return;

      setLoading(true);

      try {
        const { data } = await getGroups();
        dispatch({
          type: 'setGroups',
          newGroups: data.groups,
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Jumbotron>
      {
        loading
          ? (
            <Spinner color="primary" />
          )
          : (
            <div>
              <h1>{t('screen.home.sections.createGroup.title')}</h1>
              <AddGroup />
              {
                currentGroup && (
                  <div>
                    <hr />
                    <h1>{t('screen.home.sections.myGroups.title')}</h1>
                    <h4>{t('screen.home.sections.myGroups.selectGroup.title')}</h4>
                    <Row>
                      <Col>
                        <GroupSelector />
                      </Col>
                      <Col>
                        <Row style={{ marginLeft: 0 }}>
                          <RenameGroup />
                          <DeleteGroup />
                        </Row>
                      </Col>
                    </Row>
                    <AddRecipients />
                    <RecipientList />
                  </div>
                )
              }
            </div>
          )
      }
    </Jumbotron>
  )
}

export default Groups;
