export default ({
  getToken() {
    return localStorage.getItem('token')
  },

  getCompanyId() {
    return localStorage.getItem('companyId') || ''
  },

  getCompanyName() {
    return localStorage.getItem('companyName') || ''
  },

  getDomainNames() {
    return JSON.parse(localStorage.getItem('domainNames')) || []
  },

  setStorage({
    companyToken,
    companyId,
    companyName,
    domainNames,
  }) {
    localStorage.setItem('token', companyToken)
    localStorage.setItem('companyId', companyId)
    localStorage.setItem('companyName', companyName)
    localStorage.setItem('domainNames', JSON.stringify(domainNames))
  },
})
