/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'
import { Table, Button, Spinner } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { GroupConsumerHook } from '../../providers/groupProvider'
import { removeUsersToGroupByEmails, removeUsersToGroupByMacAddresses, changeDefaultImage } from '../../mutation/mutation'
import './styles.css'

const getRecipientStatus = (recipient, groupName, t) => {
  if (recipient.macAddress && !recipient.email) {
    return t('screen.home.sections.list.notSetupYet');
  }

  if (!recipient.macAddress && recipient.email) {
    return `👻 This user has been added to "${groupName}" but no Lovebox has been set up with this email yet. Is the email correct?`;
  }

  if (!recipient.macAddress && !recipient.email) {
    return '❓';
  }

  return '✅';
}

const getRecipientConnection = (recipient) => {
  if (recipient.isConnected) {
    return '✅';
  }

  return '❌';
}

const onImagesUpload = (macAddress, file, t) => {
  const reader = new FileReader();
  const image = new Image();

  image.onload = async () => {
    const base64 = reader.result;
    try {
      await changeDefaultImage(macAddress, base64);
      // eslint-disable-next-line no-alert
      window.alert(t('screen.home.sections.list.changeDefaultImageSuccess'));
      window.location.reload();
    } catch (e) {
      // eslint-disable-next-line no-alert
      window.alert(t('screen.home.sections.list.changeDefaultImageError'));
      console.log(e);
    }
  }
  reader.onloadend = () => {
    image.src = reader.result
  }
  reader.readAsDataURL(file);
}

const RecipientList = () => {
  const [{ currentGroup }, dispatch] = GroupConsumerHook();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleRemoveRecipient = async (recipient) => {
    // eslint-disable-next-line
    if (!recipient.macAddress && recipient.email && confirm(`Are you sure to delete recipient "${recipient.email}"`)) {
      setLoading(true);

      try {
        const { data } = await removeUsersToGroupByEmails(
          currentGroup._id,
          [recipient.email],
        );
        dispatch({
          type: 'updateGroup',
          updatedGroup: data.group,
        })
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
        window.location.reload();
      }
    // eslint-disable-next-line
    } else if (confirm(`Are you sure to delete recipient "${recipient.macAddress}"`)) {
      setLoading(true);

      try {
        const { data } = await removeUsersToGroupByMacAddresses(
          currentGroup._id,
          [recipient.macAddress],
        );
        dispatch({
          type: 'updateGroup',
          updatedGroup: data.group,
        })
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
        window.location.reload();
      }
    }
  }

  return (
    <Table striped style={{ marginTop: 30 }}>
      <thead>
        <tr>
          <th>{t('screen.home.sections.list.name')}</th>
          <th>{t('screen.home.sections.list.defaultImage')}</th>
          <th>{t('screen.home.sections.list.macAddress')}</th>
          <th>{t('screen.home.sections.list.mail')}</th>
          <th>{t('screen.home.sections.list.status')}</th>
          <th>{t('screen.home.sections.list.wifi')}</th>
          <th> </th>
        </tr>
      </thead>
      <tbody>
        {
          currentGroup && currentGroup.recipients.map((recipient) => (
            <tr key={recipient._id}>
              <td>{ recipient.name }</td>
              <td>
                <input
                  accept="image/*"
                  className="default-image-input"
                  id={`default-image-${recipient.macAddress}`}
                  onChange={(e) => (
                    e.target.files && onImagesUpload(recipient.macAddress, e.target.files[0], t)
                  )}
                  type="file"
                />
                {recipient.defaultImageUrl && (
                  <a
                    href={recipient.defaultImageUrl}
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    👁️
                  </a>
                )}
                &nbsp;&nbsp;
                <a
                  style={{ textDecoration: 'none', cursor: 'pointer' }}
                  onClick={() => document.getElementById(`default-image-${recipient.macAddress}`).click()}
                >
                  🖊️
                </a>
              </td>
              <td>{ recipient.macAddress }</td>
              <td>{ recipient.email }</td>
              <td>{ getRecipientStatus(recipient, currentGroup.name, t) }</td>
              <td>{ getRecipientConnection(recipient) }</td>
              <td>
                {
                  loading
                    ? (
                      <Spinner color="primary" />
                    )
                    : (
                      <Button onClick={() => handleRemoveRecipient(recipient)} close />
                    )
                }
              </td>
            </tr>
          ))
        }
      </tbody>
    </Table>
  );
};

export default RecipientList;
