import React, { useState } from 'react'
import {
  Col, Row, Form, FormGroup, Input, Label, Button, Spinner,
} from 'reactstrap'
import { useTranslation } from 'react-i18next';
import { GroupConsumerHook } from '../../providers/groupProvider'
import { addUsersToGroupByEmail, addUsersToGroupByMacAddresses } from '../../mutation/mutation'

const AddRecipient = () => {
  const [loading, setLoading] = useState(false);
  const [macAddressInput, setMacAddressInput] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [{ currentGroup }] = GroupConsumerHook();
  const { t } = useTranslation();

  const handleRecipients = async () => {
    if (macAddressInput) {
      setLoading(true);

      const macAddresses = macAddressInput.split('\n');

      try {
        await addUsersToGroupByMacAddresses(currentGroup._id, macAddresses);
        setMacAddressInput('');
        window.location.reload();
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    } else if (emailInput) {
      setLoading(true);

      const emails = emailInput.split('\n');

      try {
        await addUsersToGroupByEmail(currentGroup._id, emails);
        setEmailInput('');
        window.location.reload();
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Form style={{ marginTop: 30 }}>
      <h4>
        {t('screen.home.sections.addRecipient.title', {
          groupName: currentGroup.name,
        })}

      </h4>
      <Row>
        <Col xs="5">
          <FormGroup>
            <Label for="macAddressInput">{t('screen.home.sections.addRecipient.macAdress.title')}</Label>
            <Input value={macAddressInput} disabled={emailInput} onChange={(e) => setMacAddressInput(e.target.value)} type="textarea" id="macAddressInput" placeholder={t('screen.home.sections.addRecipient.macAdress.placeholder')} style={{ maxWidth: 300 }} />
          </FormGroup>
        </Col>
      </Row>
      <Button onClick={handleRecipients} disabled={!macAddressInput && !emailInput}>
        {t('screen.home.sections.addRecipient.button')}
      </Button>
      {
        loading && (
          <Spinner color="primary" style={{ marginLeft: 10 }} />
        )
      }
    </Form>
  );
};

export default AddRecipient;
