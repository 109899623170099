import React, { useState } from 'react'
import {
  Collapse, Navbar, NavbarToggler, NavbarBrand, Nav,
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
  NavItem, NavbarText,
} from 'reactstrap'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ToggleLangages from './ToggleLangages';

const NavLogged = ({ token, companyName, onLogout }) => {
  const { t } = useTranslation();
  return (
    <div>
      {token
      && (
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret style={{ color: 'white' }}>
          {t('header.connectedAs', { companyName })}
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={onLogout}>
            {t('header.logout')}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      )}
    </div>
  );
}

const Navigation = ({
  token, onLogout, companyId, companyName,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <div>
      <Navbar expand="md" dark>
        <NavbarBrand href="/">Lovebox</NavbarBrand>
        <NavbarToggler
          onClick={() => setIsOpen(!isOpen)}
          type="button"
        />
        <Collapse isOpen={isOpen} navbar>
          {token && (
            <Nav navbar>
              <NavItem>
                <NavbarText>
                  <Link to="/">{t('header.myGroups')}</Link>
                </NavbarText>
              </NavItem>
              <NavItem className="ml-3">
                <NavbarText>
                  <Link to="/send-message">{t('header.sendMessage')}</Link>
                </NavbarText>
              </NavItem>
              <NavItem className="ml-3">
                <NavbarText>
                  <Link to="/sent-messages">{t('header.sentMessages')}</Link>
                </NavbarText>
              </NavItem>
              <NavItem className="ml-3">
                <NavbarText>
                  <Link to="/scheduled-messages">{t('header.scheduledMessages')}</Link>
                </NavbarText>
              </NavItem>
            </Nav>
          )}
          <Nav navbar className="ml-auto">
            <ToggleLangages />
            <NavLogged
              companyId={companyId}
              companyName={companyName}
              token={token}
              onLogout={onLogout}
            />
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  )
}

export default Navigation
