import axios from 'axios'
import { URI } from '../providers/apolloClient'

// TODO: show error to user
const getRecipientFromEmail = (email) => axios.get(`${URI}/b2b/api/recipient?email=${email}`, { headers: { authorization: localStorage.getItem('token') } })
  .then((res) => res).catch((err) => {
    console.log('err', err)
  })

// TODO: show error to user
const getRecipientsWithActivatedBox = () => axios.get(`${URI}/b2b/api/recipient?activated=true`, { headers: { authorization: localStorage.getItem('token') } })
  .then((res) => res).catch((err) => {
    console.log('err', err)
  })

// TODO: show error to user
const getRecipientsWithNotActivatedBox = () => axios.get(`${URI}/b2b/api/recipient?activated=false`, { headers: { authorization: localStorage.getItem('token') } })
  .then((res) => res).catch((err) => {
    console.log('err', err)
  })

// TODO: show error to user
const getRecipients = () => axios.get(`${URI}/b2b/api/recipient`, { headers: { authorization: localStorage.getItem('token') } })
  .then((res) => res).catch((err) => {
    console.log('err', err)
  })

const getGroups = () => axios.get(`${URI}/b2b/api/group`, { headers: { authorization: localStorage.getItem('token') } })
  .then((res) => res).catch((err) => {
    console.log('err', err)
  })

const getSentMessages = () => axios.get(`${URI}/b2b/api/group/sent-messages`, { headers: { authorization: localStorage.getItem('token') } })
  .then((res) => res).catch((err) => {
    console.log('err', err)
  })

const getScheduledMessages = () => axios.get(`${URI}/b2b/api/group/scheduled-messages`, { headers: { authorization: localStorage.getItem('token') } })
  .then((res) => res).catch((err) => {
    console.log('err', err)
  })

export {
  // eslint-disable-next-line import/prefer-default-export
  getRecipientFromEmail,
  getRecipientsWithActivatedBox,
  getRecipientsWithNotActivatedBox,
  getRecipients,
  getGroups,
  getSentMessages,
  getScheduledMessages,
}
