/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react'
import {
  Jumbotron, Spinner, Button, Row, Col, Input, FormGroup, Label,
} from 'reactstrap'
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useTranslation } from 'react-i18next';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import GroupSelector from '../../components/GroupSelector/GroupSelector'
import { GroupConsumerHook } from '../../providers/groupProvider'
import { getGroups } from '../../query/query'
import { sendImageToGroup, sendImageToUser } from '../../mutation/mutation'
import NameSelector from '../../components/NameSelector/nameSelector';

const getRecipientByLoveboxName = (groups, targetName) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const group of groups) {
    // eslint-disable-next-line no-restricted-syntax
    for (const recipient of group.recipients) {
      if (recipient.name === targetName) {
        return recipient; // Found a recipient with the specific name
      }
    }
  }
  return null; // No recipient with the specific name found
}

const SendMessage = () => {
  const [{ groups, currentGroup }, dispatch] = GroupConsumerHook();
  const [macAddress, setMacAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [base64, setBase64] = useState();
  const { t } = useTranslation();
  const [scheduleMessage, setScheduleMessage] = useState(false);
  const [scheduleDate, setScheduleDate] = useState(new Date());
  const [canExpire, setCanExpire] = useState(false);
  const [expirationTime, setExpirationTime] = useState(1);

  useEffect(() => {
    (async () => {
      if (groups.length > 0) return;

      setLoading(true);

      try {
        const { data } = await getGroups();
        dispatch({
          type: 'setGroups',
          newGroups: data.groups,
        });
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const onImagesUpload = (file) => {
    const reader = new FileReader();
    const image = new Image();

    image.onload = () => {
      setBase64(reader.result);
    }
    reader.onloadend = () => {
      image.src = reader.result
    }
    reader.readAsDataURL(file);
  }

  const handleSendImageToGroup = async () => {
    setLoading(true);

    try {
      const { data } = await sendImageToGroup(currentGroup._id,
        {
          base64, scheduleMessage, scheduleDate, canExpire, expirationTime,
        });
      if (data) {
        dispatch({
          type: 'updateGroup',
          updatedGroup: currentGroup,
        })
      }
      setBase64();
      setScheduleMessage(false);
      setScheduleDate(new Date());
      setCanExpire(false);
      setExpirationTime(1);
      setSuccess(true);
    } catch (e) {
      console.log(e);
      setSuccess(false);
    } finally {
      setLoading(false);
      setOpen(true);
    }
  }

  const handleQuitSnackBar = () => {
    setOpen(false);
  };

  const handleSendImageToMacAddress = async () => {
    setLoading(true);

    try {
      console.log(macAddress)
      const { data } = await sendImageToUser(
        macAddress,
        {
          base64, scheduleMessage, scheduleDate, canExpire, expirationTime,
        },
      );
      console.log(data);
      setBase64();
      setScheduleMessage(false);
      setScheduleDate(new Date());
      setCanExpire(false);
      setExpirationTime(1);
      setSuccess(true);
    } catch (e) {
      console.log(e);
      setSuccess(false);
    } finally {
      setLoading(false);
      setOpen(true);
    }
  }

  const handleSendImageToLoveboxName = async (loveboxName) => {
    setLoading(true);

    const lovebox = getRecipientByLoveboxName(groups, loveboxName);

    if (!lovebox || !lovebox.macAddress) {
      setSuccess(false);
      setLoading(false);
      setOpen(true);
      return;
    }
    try {
      const { data } = await sendImageToUser(
        lovebox.macAddress,
        {
          base64, scheduleMessage, scheduleDate, canExpire, expirationTime,
        },
      );
      console.log(data);
      setBase64();
      setScheduleMessage(false);
      setScheduleDate(new Date());
      setCanExpire(false);
      setExpirationTime(1);
      setSuccess(true);
    } catch (e) {
      console.log(e);
      setSuccess(false);
    } finally {
      setLoading(false);
      setOpen(true);
    }
  }

  const dropHandler = (e) => {
    e.preventDefault();

    if (e.dataTransfer.items) {
      onImagesUpload(e.dataTransfer.items[0].getAsFile());
    }
  }

  const dragOverHandler = (e) => {
    e.preventDefault();
  }

  return (
    <Jumbotron>
      {
        loading
          ? (
            <Spinner color="primary" />
          )
          : (
            <div>
              <h3>{t('screen.sendMessage.uploadImage.title')}</h3>
              <Row style={{ marginBottom: 10, marginLeft: 0 }}>
                <input
                  accept="image/*"
                  id="message-input-1"
                  onChange={(e) => e.target.files && onImagesUpload(e.target.files[0])}
                  type="file"
                />
                <div
                  onClick={() => document.getElementById('message-input-1').click()}
                  onDrop={dropHandler}
                  onDragOver={dragOverHandler}
                  style={{
                    ...dropStyle,
                    backgroundImage: `url("${base64}")`,
                    backgroundPosition: 'center',
                    backgroundSize: '320px 240px',
                    backgroundRepeat: 'no-repeat',
                  }}
                  id="message-drop-1"
                  className="mt-4"
                >
                  { !base64 && (
                    <p>{t('screen.sendMessage.uploadImage.title')}</p>
                  )}
                </div>
              </Row>
              <Row style={{ marginBottom: 10, marginLeft: 0 }}>
                <FormGroup
                  check
                  inline
                >
                  <Input
                    type="checkbox"
                    id="canExpire"
                    checked={canExpire}
                    onChange={(e) => setCanExpire(e.target.checked)}
                  />
                  <Label
                    check
                    onClick={() => document.getElementById('canExpire').click()}
                  >
                    {t('screen.sendMessage.canExpire.title')}
                  </Label>
                </FormGroup>
                {
                    canExpire && (
                    <FormGroup>
                      <Input
                        value={expirationTime}
                        type="select"
                        onChange={(e) => {
                          setExpirationTime(parseInt(e.target.value, 10));
                        }}
                      >
                        {
                          [
                            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
                            14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
                          ].map((time) => (
                            <option value={time}>
                              {time}
                              H
                            </option>
                          ))
                        }
                      </Input>
                    </FormGroup>
                    )
                  }
              </Row>
              <Row style={{ marginBottom: 30, marginLeft: 0 }}>
                <FormGroup
                  check
                  inline
                >
                  <Input
                    type="checkbox"
                    id="scheduleMessage"
                    checked={scheduleMessage}
                    onChange={(e) => setScheduleMessage(e.target.checked)}
                  />
                  <Label
                    check
                    onClick={() => document.getElementById('scheduleMessage').click()}
                  >
                    {t('screen.sendMessage.scheduleMessage.title')}
                  </Label>
                </FormGroup>
                {
                  scheduleMessage && (
                    <FormGroup
                      check
                      inline
                    >
                      <DateTimePicker
                        locale="fr-FR"
                        style={{ marginLeft: '10px' }}
                        value={scheduleDate}
                        onChange={(date) => {
                          setScheduleDate(date);
                        }}
                      />
                    </FormGroup>
                  )
                }
              </Row>
              <h3 style={{ marginBottom: 20 }}>{t('screen.sendMessage.send.title')}</h3>
              <Row>
                <Col>
                  <h5>{t('screen.sendMessage.send.toGroup.title')}</h5>
                  <GroupSelector />
                  <Button disabled={!base64 || !currentGroup || !currentGroup.recipients.length} className="mt-4" onClick={handleSendImageToGroup} color="primary">
                    {currentGroup ? t('screen.sendMessage.send.toGroup.buttonGroup',
                      { groupName: currentGroup.name, numberOfLovebox: currentGroup.recipients.length }) : t('screen.sendMessage.send.toGroup.button')}
                  </Button>
                </Col>
                <Col>
                  <div className="flex-center">
                    <h5>{t('screen.sendMessage.send.or')}</h5>
                  </div>
                </Col>
                <Col>
                  <h5>{t('screen.sendMessage.send.toName.title')}</h5>
                  <NameSelector
                    haveBase64={!!base64}
                    handleSendImageToLoveboxName={handleSendImageToLoveboxName}
                  />
                </Col>
                <Col>
                  <div className="flex-center">
                    <h5>{t('screen.sendMessage.send.or')}</h5>
                  </div>
                </Col>
                <Col>
                  <h5>{t('screen.sendMessage.send.toMac.title')}</h5>
                  <Input value={macAddress} onChange={(e) => setMacAddress(e.target.value)} type="text" placeholder={t('screen.sendMessage.send.toMac.placeholder')} style={{ maxWidth: 300 }} />
                  <Button disabled={!base64 || (macAddress || '').length !== 12} className="mt-4" onClick={handleSendImageToMacAddress} color="primary">
                    {macAddress ? t('screen.sendMessage.send.toMac.buttonMac', { macAddress }) : t('screen.sendMessage.send.toMac.button')}
                  </Button>
                </Col>
              </Row>
              <Snackbar open={open} autoHideDuration={6000} onClose={handleQuitSnackBar}>
                <Alert onClose={handleQuitSnackBar} severity={success ? 'success' : 'error'} sx={{ width: '100%' }}>
                  { success ? 'Message successfully send' : 'Error sending message'}
                </Alert>
              </Snackbar>
            </div>
          )
      }
    </Jumbotron>
  )
}

const dropStyle = {
  width: 320,
  height: 240,
  border: '1px solid grey',
  borderRadius: 5,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
}

export default SendMessage;
