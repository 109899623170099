import React from 'react'
import { Jumbotron } from 'reactstrap'
import ActivateLoveboxForm from './forms/ActivateLoveboxForm'

const ActivateLovebox = () => (
  <div>
    <Jumbotron>
      <h3 className="display-4">
        Activate Lovebox with MAC Address
      </h3>
      <hr className="my-2" />

      <ActivateLoveboxForm />

    </Jumbotron>
  </div>
)

export default ActivateLovebox;
