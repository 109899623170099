import React from 'react'
import { useTranslation } from 'react-i18next';

import './styles.css'

const ToggleLangages = () => {
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    window.localStorage.setItem('language', lng);
  };
  return (
    <div className="container-toggle">
      <span
        aria-hidden="true"
        className={`toggle-langages__item ${i18n.language === 'fr' ? 'toggle-langages__item--active' : ''}`}
        onKeyDown={() => changeLanguage('fr')}
        onClick={() => changeLanguage('fr')}
      >
        fr
      </span>
      <div className="toggle-langages__separator">|</div>
      <span
        aria-hidden="true"
        className={`toggle-langages__item ${i18n.language === 'en' ? 'toggle-langages__item--active' : ''}`}
        onKeyDown={() => changeLanguage('en')}
        onClick={() => changeLanguage('en')}
      >
        en
      </span>
    </div>
  );
};

export default ToggleLangages;
