import React, { createContext, useContext, useReducer } from 'react';

const initialState = (() => {
  try {
    return {
      groups: [],
      currentGroup: window.localStorage.getItem('currentGroup')
        ? JSON.parse(window.localStorage.getItem('currentGroup'))
        : undefined,
    };
  } catch (e) {
    return {
      groups: [],
      currentGroup: undefined,
    };
  }
})();

const reducer = (state, action) => {
  switch (action.type) {
    case 'setGroups': {
      window.localStorage.setItem('currentGroup', JSON.stringify(
        action.newGroups.length
          ? (action.newGroups.find(
            (g) => (state.currentGroup && g._id === state.currentGroup._id),
          ) || action.newGroups[0])
          : state.currentGroup,
      ));
      return {
        ...state,
        groups: action.newGroups,
        currentGroup: action.newGroups.length
          ? (action.newGroups.find(
            (g) => (state.currentGroup && g._id === state.currentGroup._id),
          ) || action.newGroups[0])
          : state.currentGroup,
      }
    }
    case 'addGroup': {
      window.localStorage.setItem('currentGroup', JSON.stringify(action.newGroup || state.currentGroup));
      return {
        ...state,
        groups: [...state.groups, action.newGroup],
        currentGroup: action.newGroup || state.currentGroup,
      }
    }
    case 'renameGroup': {
      const renamedGroup = state.groups.find((group) => group._id === action.renamedGroupId);
      renamedGroup.name = action.renamedGroupName;

      const otherGroups = state.groups.filter((group) => group._id !== action.renamedGroupId);

      window.localStorage.setItem('currentGroup',
        JSON.stringify(state.currentGroup._id === action.renamedGroupId
          ? renamedGroup
          : state.currentGroup));
      return {
        ...state,
        groups: [...otherGroups, renamedGroup],
        currentGroup: state.currentGroup._id === action.renamedGroupId
          ? renamedGroup
          : state.currentGroup,
      }
    }
    case 'deleteGroup': {
      const otherGroups = state.groups.filter((group) => group._id !== action.deletedGroupId);
      window.localStorage.setItem('currentGroup', JSON.stringify((otherGroups.length && otherGroups[0]) || state.currentGroup));
      return {
        ...state,
        groups: otherGroups,
        currentGroup: (otherGroups.length && otherGroups[0]) || state.currentGroup,
      }
    }
    case 'updateGroup': {
      const otherGroups = state.groups.filter((group) => group._id !== action.updatedGroup._id);
      window.localStorage.setItem('currentGroup', JSON.stringify(state.currentGroup._id === action.updatedGroup._id
        ? action.updatedGroup
        : state.currentGroup));
      return {
        ...state,
        groups: [...otherGroups, action.updatedGroup],
        currentGroup: state.currentGroup._id === action.updatedGroup._id
          ? action.updatedGroup
          : state.currentGroup,
      }
    }
    case 'changeCurrentGroup': {
      window.localStorage.setItem('currentGroup', JSON.stringify(state.groups.find((group) => group._id === action.newCurrentGroupId)));
      return {
        ...state,
        currentGroup: state.groups.find((group) => group._id === action.newCurrentGroupId),
      };
    }
    default:
      return state;
  }
};

const GroupContext = createContext();

export const GroupConsumer = GroupContext.Consumer;

export const GroupConsumerHook = () => useContext(GroupContext);

export const GroupProvider = ({ children }) => (
  <GroupContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </GroupContext.Provider>
);
