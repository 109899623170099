import React from 'react';
import CheckStatus from '../../components/CheckStatus/CheckStatus'
import ActivateLovebox from '../../components/ActivateLovebox/ActivateLovebox'

const CapitalOneMainPage = () => (
  <div>
    <CheckStatus />
    <ActivateLovebox />
  </div>
)

export default CapitalOneMainPage;
