import React, { Component, useState, useEffect } from 'react'
import {
  Button, Form, FormGroup, Input, Jumbotron, Row, Spinner,
} from 'reactstrap'
import axios from 'axios'
import { URI } from '../../providers/apolloClient'
import i18n from '../../i18n'

const formatErrorMessage = (error, t) => {
  if (!error) return '';
  switch (error) {
    case 'INVALID_API_KEY':
      return t('screen.login.errors.invalidAPIKey')
    case 'EMPTY_API_KEY':
      return t('screen.login.errors.emptyAPIKey')
    default:
      return t('screen.login.errors.unknown')
  }
}

const ErrorMsg = ({ error, t }) => {
  const [errorText, setErrorText] = useState(formatErrorMessage(error, t));
  useEffect(() => {
    setErrorText(formatErrorMessage(error, t))
  }, [error]);
  return (
    <Row>
      <p className="color-danger bold login-error-message">
        {errorText}
      </p>
    </Row>
  )
}

class LoginPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      apiKey: '',
      error: '',
      loading: false,
    }

    this.handleApiKey = this.handleApiKey.bind(this)
    this.handleLogin = this.handleLogin.bind(this)
    this.handleError = this.handleError.bind(this)
    this.login = this.login.bind(this)
  }

  handleLogin(data) {
    const { onLogin } = this.props;
    onLogin(data)
  }

  handleApiKey(e) {
    this.setState({
      apiKey: e.target.value,
      loading: false,
      error: '',
    })
  }

  handleError(error) {
    this.setState({
      error,
    })
  }

  login(e) {
    const { apiKey } = this.state;
    this.setState({ loading: true });
    e.preventDefault()
    if (apiKey === '') {
      this.handleError('EMPTY_API_KEY')
      this.setState({ loading: false })
      return
    }
    axios.post(`${URI}/auth/b2bLoginWithAPIKey`, this.state)
      .then((res) => {
        const {
          _id: companyId,
          name: companyName,
          domainNames,
          language,
        } = res.data.company;
        i18n.changeLanguage(language);
        this.handleLogin({
          companyId,
          companyName,
          domainNames,
          companyToken: apiKey,
        });
        this.setState({ loading: false });
      }).catch((err) => {
        this.handleError(err.response.data.error)
        this.setState({ loading: false });
      })
  }

  render() {
    const { apiKey, error, loading } = this.state;
    const { t } = this.props;
    return (
      <Jumbotron>
        <h3 className="login-title display-4">
          {t('screen.login.enterAPI')}
        </h3>

        <Form onSubmit={this.login}>
          <FormGroup>
            <Input
              type="password"
              value={apiKey}
              style={{ fontSize: 20, fontWeight: 'bold' }}
              onChange={this.handleApiKey}
            />
          </FormGroup>
          <ErrorMsg error={error} t={t} />
          {
            loading
              ? <Spinner color="primary" />
              : (
                <Button
                  type="submit"
                  color="primary"
                  className="login-button"
                >
                  {t('screen.login.login')}
                </Button>
              )
          }
        </Form>
      </Jumbotron>
    )
  }
}

export default LoginPage
