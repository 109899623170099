/* eslint-disable */

import React, { useCallback, useState } from 'react'
import {
  FormGroup, Label, Input, Button, Table, Toast, ToastHeader, ToastBody, Spinner,
} from 'reactstrap'
import CustomerSearch from './customer-search'
import {
  getRecipientsWithActivatedBox,
  getRecipientsWithNotActivatedBox,
  getRecipientFromEmail,
  getRecipients,
} from '../../../query/query'
import { activateLovebox } from '../../../mutation/mutation'

const ApiError = () => (
  <div className="color-danger">
    An error occured when trying to communicate to API.
    You can contact Lovebox to report the problem.
  </div>
)

const EmailDoesntExists = (email) => (
  <div className="color-danger">
    The given email :
    {' '}
    <span className="bold">{email}</span>
    {' '}
    is not found in your company.
  </div>
)

const TodoCell = ({
  requestTypeProp, recipient: { macAddress, email } = {}, handleActivateClick,
}) => {
  const [loading, setLoading] = useState(false);
  const [requestType] = useState(requestTypeProp);
  switch (requestType) {
    case REQUEST_TYPE_ENUM.ACTIVATED_BOX:
      return (
        <td>The Lovebox is activated and ready to receive messages!</td>
      )
    case REQUEST_TYPE_ENUM.NOT_ACTIVATED_BOX || REQUEST_TYPE_ENUM.EMAIL:
      if (macAddress) {
        return (
          <td>
            The Lovebox has been set up, but its owner has not click on the activation email.
            Please tell him.her to click on the activation email, or manually activate the Lovebox
            by clicking the button below.
            <br />
            {
              loading
                ? (
                  <Spinner color="primary" />
                )
                : (
                  <Button
                    color="danger"
                    onClick={() => handleActivateClick({ macAddress, setLoading })}
                  >
                    Activate Lovebox
                  </Button>
                )
            }
          </td>
        )
      }
      return (
        <td>
          {`No Lovebox found for this email. Please set up the Lovebox and check you entered "${email}" in the email field.`}
        </td>
      )
    case REQUEST_TYPE_ENUM.ALL:
      if (macAddress) {
        return (
          <td>The Lovebox is activated and ready to receive messages!</td>
        )
      }
      return (
        <td>
          The Lovebox is not activated yet.
          List &quot;non-activated Loveboxes&quot; for more details.
        </td>
      )
    case REQUEST_TYPE_ENUM.EMAIL:
      if (macAddress) {
        return (
          <td>The Lovebox is activated and ready to receive messages!</td>
        )
      }
      return (
        <td>
          The Lovebox is not activated yet.
          List &quot;non-activated Loveboxes&quot; for more details.
        </td>
      )
    default:
      return <td />
  }
}

const REQUEST_TYPE_ENUM = Object.freeze({
  ACTIVATED_BOX: 'ACTIVATED_BOX',
  NOT_ACTIVATED_BOX: 'NOT_ACTIVATED_BOX',
  ALL: 'ALL',
  EMAIL: 'EMAIL',
});

const LoveboxForm = ({
  email, setEmail,
}) => {
  const [recipients, setRecipients] = useState();
  const [requestType, setRequestType] = useState();
  const [activationSuccess, setActivationSuccess] = useState(false);
  const [activationError, setActivationError] = useState(false);
  const [activationErrorMessage, setActivationErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailDoesntExistsError, setEmailDoesntExistsError] = useState(false);
  const [apiError, setApiError] = useState(false);

  const handleActivateClick = useCallback(({ macAddress, setLoading: setActivationLoading }) => {
    setActivationLoading(true);
    activateLovebox(macAddress)
      .then((res) => {
        if (res.data.recipient.email && res.data.recipient.macAddress) {
          setRecipients(recipients
            .filter((recipient) => recipient.macAddress !== res.data.recipient.macAddress))
        }
        setActivationLoading(false);
        setActivationSuccess(true);
      })
      .catch((err) => {
        if (err.response.data.error === 'BOX_INVALID_EMAIL') {
          setActivationErrorMessage("The box hasn't been configured with an email with your company domain name");
        }
        setActivationLoading(false);
        setActivationError(true);
      })
  }, [activateLovebox, setRecipients, recipients, setActivationSuccess, setActivationErrorMessage]);

  const handleClick = useCallback(() => {
    if (requestType === REQUEST_TYPE_ENUM.EMAIL) {
      setLoading(true);
      // TODO: error is not handled
      getRecipientFromEmail(email)
        .then((res) => {
          setLoading(false);
          if (!res || !res.data) {
            setApiError(true);
            return;
          }
          if (res.data.recipients.length === 0) {
            setEmailDoesntExistsError(true);
            return;
          }
          setRecipients(res.data.recipients);
        });
    } else if (requestType === REQUEST_TYPE_ENUM.ACTIVATED_BOX) {
      setLoading(true);
      // TODO: error is not handled
      getRecipientsWithActivatedBox().then((res) => {
        setLoading(false);
        setRecipients(res.data.recipients);
      });
    } else if (requestType === REQUEST_TYPE_ENUM.NOT_ACTIVATED_BOX) {
      setLoading(true);
      // TODO: error is not handled
      getRecipientsWithNotActivatedBox().then((res) => {
        setLoading(false);
        setRecipients(res.data.recipients);
      });
    } else if (requestType === REQUEST_TYPE_ENUM.ALL) {
      setLoading(true);
      // TODO: error is not handled
      getRecipients().then((res) => {
        setLoading(false);
        setRecipients(res.data.recipients);
      });
    }
  }, [email, requestType, getRecipientFromEmail, setLoading, setApiError, setEmailDoesntExistsError,
    setRecipients, getRecipients, getRecipientsWithActivatedBox, getRecipientsWithNotActivatedBox]);

  return (
    <div>
      <FormGroup tag="fieldset">
        <legend>Search:</legend>
        <FormGroup check>
          <Label check>
            <Input type="radio" name="radio1" value={requestType} onChange={() => setRequestType(REQUEST_TYPE_ENUM.ACTIVATED_BOX)} />
            {' '}
            Activated Loveboxes
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input type="radio" name="radio1" value={requestType} onChange={() => setRequestType(REQUEST_TYPE_ENUM.NOT_ACTIVATED_BOX)} />
            {' '}
            Non-activated Loveboxes
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input type="radio" name="radio1" value={requestType} onChange={() => setRequestType(REQUEST_TYPE_ENUM.ALL)} />
            {' '}
            All Loveboxes
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input type="radio" name="radio1" value={requestType} onChange={() => setRequestType(REQUEST_TYPE_ENUM.EMAIL)} />
            {' '}
            One Lovebox by owner&apos;s email
          </Label>
        </FormGroup>
      </FormGroup>
      {requestType === REQUEST_TYPE_ENUM.EMAIL && (
        <div>
          <CustomerSearch
            email={email}
            setEmail={setEmail}
            onButtonClick={() => handleClick()}
          />
        </div>
      )}
      {
        loading
          ? (
            <Spinner color="primary" />
          )
          : (
            <Button
              disabled={requestType === REQUEST_TYPE_ENUM.EMAIL && email.length < 5}
              color="danger"
              onClick={() => handleClick()}
            >
              Search
            </Button>
          )
      }
      {
          apiError && !loading
          && (
          <ApiError />
          )
        }
      { emailDoesntExistsError && !loading
          && (
          <EmailDoesntExists email={email} />
          )}
      {
        recipients && !loading
        && (
        <div style={{
          overflowY: 'auto', maxHeight: '50vh', width: '100%', marginTop: 20,
        }}
        >
          Recipients:
          <Table striped>
            <thead>
              <tr>
                <th>#</th>
                <th>id</th>
                <th>Email</th>
                <th>Lovebox status</th>
              </tr>
            </thead>
            <tbody>
              {
                    recipients.map((recipient, index) => (
                      <tr key={recipient._id}>
                        <th scope="row">{index + 1}</th>
                        <td>{recipient._id}</td>
                        <td>{recipient.email}</td>
                        <TodoCell
                          requestTypeProp={requestType}
                          recipient={recipient}
                          handleActivateClick={handleActivateClick}
                        />
                      </tr>
                    ))
                  }
            </tbody>
          </Table>
        </div>
        )
      }
      {
activationError
      && (
        <div
          style={{
            position: 'fixed', bottom: '1%', left: '2.5%', width: 'auto', cursor: 'pointer',
          }}
          className="p-3 bg-danger my-2 rounded"
          onClick={() => {
            setActivationErrorMessage('');
            setActivationError(false);
          }}
        >
          <Toast>
            <ToastHeader>
              Error on activating
            </ToastHeader>
            <ToastBody>
              {activationErrorMessage}
            </ToastBody>
          </Toast>
        </div>
      )
      }
      { activationSuccess && (
        <div
          style={{
            position: 'fixed', bottom: '1%', left: '2.5%', width: 'auto', cursor: 'pointer',
          }}
          className="p-3 bg-success my-2 rounded"
          onClick={() => {
            setActivationSuccess(false);
          }}
        >
          <Toast>
            <ToastHeader>
              Successfully activated!
            </ToastHeader>
            <ToastBody>
              The box has been activated!
            </ToastBody>
          </Toast>
        </div>
      )}
    </div>
  )
};

export default LoveboxForm
