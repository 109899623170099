import React, { useCallback, useEffect, useState } from 'react'
import {
  Form, FormGroup, Label, Input, Container, Row, Col, Button, Spinner,
} from 'reactstrap'
import { activateLovebox } from '../../../mutation/mutation'

const ENUM_RESPONSES_ERRORS = {
  BOX_DOESNT_EXISTS: 'BOX_DOESNT_EXISTS',
  BOX_NOT_SETUP: 'BOX_NOT_SETUP',
  BOX_INVALID_EMAIL: 'BOX_INVALID_EMAIL',
}

const ActivateLoveboxForm = () => {
  const [boxError, setBoxError] = useState();
  const [macAddress, setMacAddress] = useState('');
  const [successActivating, setSuccessActivating] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setBoxError(undefined);
  }, [macAddress]);

  const activateBox = useCallback(() => {
    setLoading(true);
    activateLovebox(macAddress)
      .then((res) => {
        setLoading(false);
        if (res.data.recipient.email && res.data.recipient.macAddress) {
          setSuccessActivating(true);
        }
      })
      .catch((err) => {
        setLoading(false);
        setBoxError(err.response.data.error);
      })
  }, [setLoading, macAddress, setSuccessActivating, setBoxError]);

  return (
    <div>
      <Form>
        <FormGroup>
          <Label>MAC Address</Label>
          <Container>
            <Row>
              <Col>
                <Input
                  type="text"
                  placeholder="1234567890AB"
                  value={macAddress}
                  onChange={({ target: { value } }) => setMacAddress(value.toUpperCase())}
                />
              </Col>
            </Row>
          </Container>
        </FormGroup>
        <FormGroup>
          {
            loading
              ? <Spinner color="primary" />
              : (
                <Button
                  disabled={macAddress.length !== 12}
                  color="danger"
                  onClick={activateBox}
                >
                  Activate Lovebox
                </Button>
              )
          }
        </FormGroup>
      </Form>
      {
        successActivating && !loading
        && (
        <p className="color-success bold">
          The MAC address
          {' '}
          {macAddress}
          {' '}
          has been activated with success!
        </p>
        )
      }
      {
        boxError === ENUM_RESPONSES_ERRORS.BOX_DOESNT_EXISTS
        && (
        <p className="color-danger bold">
          {`No Lovebox found for MAC Address "${macAddress}", please try again.`}
        </p>
        )
      }
      {
        boxError === ENUM_RESPONSES_ERRORS.BOX_NOT_SETUP
        && (
          <div>
            <p className="color-danger bold">
              The Lovebox with the given MAC address is not
              linked to any account. Tell the user to re-configured
              his Lovebox and check if he used the company email.
            </p>
          </div>
        )
      }
      {
        boxError === ENUM_RESPONSES_ERRORS.BOX_INVALID_EMAIL
        && (
          <div>
            <p className="color-danger bold">
              The Lovebox with the given MAC address has been setup with a different email
              from your company domain name.
              It needs to be re-setup.
            </p>
          </div>
        )
      }
    </div>
  )
}

export default ActivateLoveboxForm;
